<template>
  <div class="form">
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('requestForm.banner.tagReplacement') }}</v-card-title>
      </v-img>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-form ref="form" v-model="valid" :lazy-validation="true">
              <PersonalData v-model="form.personalData" />

              <v-row class="px-5">
                <v-col cols="8">
                  <span
                    class="subtitle-2"
                  >{{ $t('requestForm.accessTags.hasOwnTags.sectionHeader') }}</span>
                  <br />
                  <i class="caption">{{ $t('requestForm.accessTags.hasOwnTags.instruction') }}</i>
                  <v-radio-group v-model="hasOwnTags" :rules="radioRules">
                    <v-radio :label="$t('requestForm.accessTags.hasOwnTags.yes')" :value="true"></v-radio>
                    <v-radio :label="$t('requestForm.accessTags.hasOwnTags.no')" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col md="4" cols="6">
                  <span class="subtitle-2">{{ $t('requestForm.pin.header') }}</span>
                  <br />
                  <i class="caption">{{ $t('requestForm.pin.instruction') }}</i>
                  <v-text-field
                    v-model="form.accessTags.pin"
                    class="mt-5"
                    filled
                    required
                    :rules="tagPinRules"
                    :label="$t('requestForm.accessTags.pinCode')"
                  ></v-text-field>
                </v-col>
              </v-row>

              <AccessTags v-if="hasOwnTags" v-model="form.accessTags" />
              <DeliveryAddress v-else v-model="form.deliveryAddress" />
              <ReasonDescription v-model="form.accessReason" />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="sendButtonDisabled"
          class="px-5"
          rounded
          @click="handleSend"
        >{{ $t('actions.send') }}</v-btn>
        <v-btn color="primary" rounded outlined @click="handlePrint">{{ $t('actions.print') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-container class="white lighten-5 pt-10"></v-container>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import validators from '../validators'
export default {
  name: 'TagReplacementForm',
  data: () => ({
    dayjs,
    ...validators,
    valid: false,
    snackbar: false,
    hasOwnTags: true,
    sendButtonDisabled: false,
    form: {
      type: 'replacement',
      personalData: {
        isSwedishNational: undefined,
        socialSecurityNumber: null,
        passportNumber: null,
        birthDate: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        profession: null,
        userId: null
      },
      accessTags: {
        pin: null,
        so: null,
        rc: null,
        mi: null
      },
      deliveryAddress: {
        recipient: '',
        attestant: '',
        street: '',
        zipcode: '',
        city: '',
        country: ''
      },
      accessReason: {
        reason: null
      }
    }
  }),
  computed: {
    ...mapGetters(['isValidated'])
  },
  watch: {
    isValidated (v) {
      v || this.$router.push('/')
    }
  },
  methods: {
    ...mapActions(['sendRequest', 'createMessage']),
    async handleSend () {
      if (this.$refs.form.validate()) {
        try {
          this.sendButtonDisabled = true
          await this.sendRequest(this.form)
        } finally {
          this.sendButtonDisabled = false
        }
      } else {
        this.createMessage({ text: this.$t('messages.formValidationWarning'), color: 'warning' })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    handlePrint () {
      window.print()
    }
  }
}
</script>
